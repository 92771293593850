.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.h-50 {
    height: 50%;
}

.w-33 {
    width: 33.3%;
}

.w-50 {
    width: 50%;
}

.text-bold {
    font-weight: bold !important;
}

.item-button-style {
    border-radius: var(--woonig-border-radius);

    button {
        padding: 0;
    }
}

.text-light {
    color: #999;
}

.separator-top {
    border-top: var(--gray-border);
}

.no-padding-top {
    padding-top: 0px !important;
}

.display-flex {
    display: flex;
}

.font-sm {
    font-size: var(--font-sm);
}

.icon-lg {
    font-size: var(--icon-size-lg);
}

ion-card-header {
    .button-right {
        float: right;
        margin-top: -6px;
    }
}

.hidden {
    display: none;
}

ion-toolbar[color='primary'] {
    ion-title,
    ion-icon:not(.searchbar-search-icon.md) {
        color: white !important;
    }
}

ion-fab-button.tiny {
    height: 20px;
    width: 20px;
    ion-icon {
        font-size: var(--font-size-caption);
    }
}

ion-card {
    border-radius: var(--woonig-border-radius);

    .card-section {
        padding: var(--spacing-md) 0;

        .section-title {
            font-size: var(--font-size-base);
            text-transform: uppercase;
            font-weight: bold;
            margin: 0 var(--spacing-sm) var(--spacing-md);
        }
    }

    &.accent-border {
        border-top: 2px solid var(--ion-color-primary);
    }
}

.color-primary {
    color: var(--ion-color-primary);
}

.topbar-in-content {
    .md & {
        box-shadow: var(--header-box-shadow-android);
    }
    angular .ios & {
        border-bottom: var(--header-border-ios);
    }
}

.scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
}

ion-modal.pop-up {
    --width: 80%;
    --max-height: 50%;
    --min-height: 40%;
    --max-width: 700px;
    --border-radius: var(--woonig-border-radius);
}

ion-backdrop {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
}

/* RECAPTCHA FOR CONTACTFORM */
.grecaptcha-badge {
    visibility: hidden;
}

body.show-recaptcha-badge {
    .grecaptcha-badge {
        visibility: visible;
    }

    @media screen and (max-width: 768px) {
        .grecaptcha-badge {
            bottom: 115px !important;
        }
    }
}

body.top-recaptcha-badge {
    .grecaptcha-badge {
        top: 0;
    }
}

@media screen and (max-width: 600px) {
    .small-modal .modal-wrapper {
        height: 90%;
        width: 90%;
    }

    .smaller-modal .modal-wrapper {
        height: 225px;
        width: 90%;
        border-radius: var(--woonig-border-radius);
    }
}

.delete-option {
    color: var(--ion-color-danger) !important;
    ion-icon {
        color: var(--ion-color-danger) !important;
    }
}
