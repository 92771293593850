// http://ionicframework.com/docs/theming/
@import '@ionic/angular/css/core.css';
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';
@import '@ionic/angular/css/padding.css';
@import '@ionic/angular/css/float-elements.css';
@import '@ionic/angular/css/text-alignment.css';
@import '@ionic/angular/css/text-transformation.css';
@import '@ionic/angular/css/flex-utils.css';
@import 'app/styles/_global.scss';
@import 'app/styles/_fixes.scss';
@import 'app/styles/_desktop.scss';
html {
    font-size: var(--default-font-size);
    background-color: var(--menu-background, #ffffff);
    &.ios {
        font-size: var(--default-font-size-ios);
    }
}

ion-app,
ion-content {
    margin: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left) !important;
}

ion-tab-bar {
    padding-bottom: 0;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    height: var(--header-height);

    ion-tab-button {
        padding: 0.75rem 0 0.75rem 0;
        display: flex;
        justify-content: space-between;
    }
}

ion-toolbar {
    height: calc(var(--header-height)) !important;
    --background: var(--menu-background, #ffffff) !important;

    ion-segment {
        height: calc(var(--header-height) - 3px) !important;
    }
}

ion-toolbar.votebar {
    height: auto !important;
    --background: var(--ion-color-light, #ffffff) !important;
}

ion-toolbar.document-upload {
    height: auto !important;
    --background: var(--ion-color-light, #ffffff) !important;
}

ion-toolbar.ticket-detail-bar {
    --background: var(--ion-color-light, #ffffff) !important;
}

ion-toolbar.delegation-toolbar {
    height: auto !important;
}

.button-with-radius {
    --border-radius: var(--woonig-border-radius);
}

ion-chip {
    --border-radius: var(--woonig-border-radius);
}
