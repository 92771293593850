ion-fab-button,
ion-icon {
    outline: none !important;
}

ion-chip > ion-label {
    line-height: 15px;
}

ion-avatar > img {
    transform: none !important;
}

form {
    ion-item.item-interactive.item-has-focus {
        --highlight-background: var(--ion-color-primary);
    }
}

app-document-view {
    ion-list {
        padding: 0 !important;
    }
}
