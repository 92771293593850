@media screen and (min-width: 576px) {
    app-profile,
    app-token-profile {
        .profile-inner {
            width: 80%;
            margin: auto;
        }
    }

    app-login > ion-content > ion-row {
        width: 70%;
        margin: auto;
    }

    app-start {
        img {
            width: 50% !important;
        }
    }
}

@media screen and (min-width: 768px) {
    app-ticket-view {
        height: 100%;

        .ticket-view-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            app-ticket-card,
            app-skeleton-card {
                width: 100%;
                max-width: 700px;
            }
        }

        app-no-data {
            margin: auto;
        }
    }

    app-vote2-view,
    app-vote-view {
        height: 100%;

        .vote-view-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;

            app-vote2-card,
            app-vote-card {
                width: 100%;
                max-width: 700px;
            }
        }
    }

    app-blackboard {
        .blackboard-cards {
            display: flex;
            flex-wrap: wrap;

            app-blackboard-message-card,
            app-skeleton-blackboard-message {
                width: 50%;
            }
        }
    }

    app-documents {
        app-document-view {
            ion-list {
                width: 60%;
                margin: auto !important;
            }
        }

        ion-list {
            width: 60%;
            margin: auto !important;
        }
    }

    app-create-ticket app-chat-view > ion-content > div,
    app-create-ticket app-chat-view ion-footer > ion-row {
        width: 60%;
        margin: auto;
    }
}

@media screen and (min-width: 1200px) {
    app-ticket-view {
        app-ticket-card,
        app-skeleton-card {
            width: 33.3% !important;
        }
    }

    app-profile,
    app-token-profile {
        .profile-inner {
            width: 50%;
            margin: auto;
        }
    }

    app-login > ion-content > ion-row {
        width: 50%;
        margin: auto;
    }

    app-start {
        img {
            width: 30% !important;
        }
    }

    app-blackboard {
        .blackboard-cards {
            display: flex;
            flex-wrap: wrap;

            app-blackboard-message-card,
            app-skeleton-blackboard-message {
                width: 33.3%;
            }
        }
    }

    app-create-ticket app-chat-view > ion-content > div,
    app-create-ticket app-chat-view ion-footer > ion-row {
        width: 50%;
        margin: auto;
    }
}

app-blackboard,
app-news,
app-profile,
app-token-profile {
    ion-header {
        ion-title {
            text-align: center;
        }
    }
}

@media screen and (min-height: 1000px) {
    .appointments-modal {
        --height: 656px;
    }
}
